
import { defineComponent, ref } from 'vue'
import ModalBase from '@/components/common/ModalBase.vue'
import Checkbox from '@/components/common/CheckBox.vue'
import isMobile from 'ismobilejs'

export default defineComponent({
  name: 'NoticeModal',
  components: {
    ModalBase,
    Checkbox
  },
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:show'],
  setup (props, { emit }) {
    const neverShow = ref(false)

    const closeModal = () => {
      if (neverShow.value) {
        sessionStorage.setItem('hideNotice20250108', 'true')
      }
      emit('update:show', false)
    }

    return {
      neverShow,
      closeModal,
      isMobile
    }
  }
})
